import axios from 'axios';

const url = process.env.REACT_APP_RESTAPI;
const urlV3 = process.env.REACT_APP_RESTAPI_V3;
const xApiKey = process.env.REACT_APP_XAPIKEY;
const wsLogin = process.env.REACT_APP_WEBSOCKET_LOGIN_V3;

export const APIPostLogin = async (setLoading, params) => {
    setLoading(true);
    const result = await axios.post(`${urlV3}/login`, params, {
        headers: {
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};

export const APIWSConnect = async (token, agencyOid) => {
    const result = await axios.post(wsLogin, null, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyOid,

        },
    }).catch((error) => error);
    return result;
};

export const APIInviteTeam = async (token, agencyId, params) => {
    const result = await axios.post(`${urlV3}/team`, params, {
        headers: {
            Authorization: token,
            'agency-id': agencyId,
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};

export const APIRequestResetPassword = async (params, setLoading) => {
    setLoading(true);
    const result = await axios.post(`${urlV3}/requestResetPassword`, params, {
        headers: {
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};

export const APICheckValidPassword = async (params) => {
    const result = await axios.post(`${urlV3}/verifyPasswordOid`, params, {
        headers: {
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};

export const APICreateAgency = async (token, agencyId, params) => {
    const result = await axios.post(`${urlV3}/agency`, params, {
        headers: {
            Authorization: token,
            'agency-id': agencyId,
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};

export const APIUpdateChannel = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.post(`${urlV3}/channel`, params, {
        headers: {
            Authorization: token,
            'agency-id': agencyId,
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};

export const APITestMessage = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.post(`${urlV3}/channel/test`, params, {
        headers: {
            Authorization: token,
            'agency-id': agencyId,
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};

export const APIVerifyEmail = async (params) => {
    const result = await axios.post(`${urlV3}/verifyEmail`, params, {
        headers: {
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};

export const APICreateGeofence = async (token, setLoading, agencyId, params) => {
    setLoading(true);
    const result = await axios.post(`${urlV3}/geofence`, params, {
        headers: {
            Authorization: token,
            'agency-id': agencyId,
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};

export const APICreateDevice = async (token, setLoading, agencyId, params) => {
    setLoading(true);
    const result = await axios.post(`${urlV3}/devices`, params, {
        headers: {
            Authorization: token,
            'agency-id': agencyId,
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};

export const APISignUpUser = async (setLoading, params) => {
    setLoading(true);
    const result = await axios.post(`${urlV3}/signup`, params, {
        headers: {
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};
