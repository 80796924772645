/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useContext, useState } from 'react';
import './menu-option-item.css';
import Switch from './img/switchAgency.svg';
import Logout from './img/logout.svg';
import Configurations from './img/configurations.svg';
import LiveMap from './img/liveMap.svg';
import Telemetry from './img/telemetry.svg';
import Delete from './img/deleteIcon.svg';
import Disable from './img/disabledIcon.svg';
import Enabled from './img/enabledIcon.svg';
import Edit from './img/editIcon.svg';

export default function MenuOptionItem(props) {
    const {
        label, menuButtonClicked, disable, bottomBorder,
     } = props;
    function navIcon() {
        switch (label) {
            case 'Switch Agency Workspace':
                return <img loading="lazy" src={Switch} className="icon-nav" alt="nav-icon-switch" />;
            case 'Log out':
                return <img loading="lazy" src={Logout} className="icon-nav" alt="nav-icon-logout" />;
            case 'Configurations':
                return <img loading="lazy" src={Configurations} className="icon-nav" alt="nav-icon-configurations" />;
            case 'Live Map Overview':
                return <img loading="lazy" src={LiveMap} className="icon-nav" alt="nav-icon-livemap" />;
            case 'Telemetry Logs':
                return <img loading="lazy" src={Telemetry} className="icon-nav" alt="nav-icon-telemetry" />;
            case 'Delete':
                return <img loading="lazy" src={Delete} className="icon-nav" alt="nav-icon-delete" />;
            case 'Disable Policy':
                return <img loading="lazy" src={Disable} className="icon-nav" alt="nav-icon-disable" />;
            case 'Enable Policy':
                return <img loading="lazy" src={Enabled} className="icon-nav" alt="nav-icon-enabled" />;
            case 'Edit':
                return <img loading="lazy" src={Edit} className="icon-nav" alt="nav-icon-edit" />;
            case 'Log out of all devices':
                return <img loading="lazy" src={Logout} className="icon-nav" alt="nav-icon-logout" />;
            default:
                return null;
        }
    }

    const labelColor = () => {
        if (disable) {
            return { color: '#D0D5DD' };
        } if (label === 'Delete') {
            return { color: '#D92D20' };
        }
            return { color: '#344054' };
    };

    return (
        <div className={bottomBorder ? 'menupopup-item-container' : 'menupopup-item-container-without-bottomborder'}>
            <div
              className="nav-justify-menupopup"
              style={disable ? { cursor: 'default' } : { cursor: 'pointer' }}
            >
                <div className="icon-nav-container">
                    {navIcon()}
                </div>
                <div
                  className="menupopup-text"
                  style={labelColor()}
                  onClick={(e) => menuButtonClicked(e)}
                >
                    {label}
                </div>
            </div>
        </div>
    );
}
