import React from 'react';
import './navigation.css';
import Navigation from './Navigation';

const ListNavigationSidebar = (props) => {
    const {
 list, navigationState, setNavigationState, setNavigationClick,
} = props;
    return (
        <div className="nav-container">
            {
                list.map((x) => x.enabled === true && (
                    <Navigation text={x.text} value={x.value} path={x.path} setNavigationState={setNavigationState} navigationState={navigationState} setNavigationClick={setNavigationClick} />
                ))
            }
        </div>
    );
};

export default ListNavigationSidebar;
