import React, { forwardRef } from 'react';
import './Checkbox.css';

const Checkbox = forwardRef(({
 onClick, width, height, borderRadius, text, value, checked, disabled, ...rest
}, ref) => (
        // <label className="form-control" htmlFor={ref}>
        //     {console.log('ref :', ref)}
        //     <input
        //       type="checkbox"
        //       name="checkbox"
        //       ref={ref}
        //       style={{ width: width, height: height, borderRadius: borderRadius }}
        //       onClick={onClick}
        //       {...rest}
        //     />
        // </label>
        <>
        <input
          id={value}
          value={value}
          type="checkbox"
          name={text}
          ref={ref}
          style={{
            width: width, height: height, borderRadius: borderRadius,
          }}
          onClick={onClick}
          checked={checked}
          disabled={disabled}
          {...rest}
        />
        <label htmlFor={value} className="form-control" style={disabled ? { color: '#667085' } : {}}>
          {text}
        </label>
        </>
));

export default Checkbox;
