import React from 'react';
import './notification-badge.css';
import PropTypes from 'prop-types';

export default function NotificationBadge(props) {
    const {
        text,
    } = props;
    return (
        <div className="notification-container">
            <div className="notification-text">
                {text}
            </div>
        </div>
    );
}

NotificationBadge.propTypes = {
    text: PropTypes.string,
};
